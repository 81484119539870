<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M7.49992 2.81337C7.49967 3.55954 7.20302 4.27505 6.67522 4.80249C6.14742 5.32994 5.43172 5.62612 4.68555 5.62587C3.93938 5.62562 3.22387 5.32897 2.69642 4.80117C2.16897 4.27337 1.8728 3.55766 1.87305 2.81149C1.8733 2.06532 2.16995 1.34981 2.69775 0.822367C2.95909 0.561202 3.26931 0.354069 3.61069 0.212794C3.95208 0.0715198 4.31796 -0.00113004 4.68742 -0.00100692C5.05689 -0.00088381 5.42271 0.0720098 5.76401 0.213512C6.1053 0.355014 6.41538 0.562354 6.67655 0.823693C6.93771 1.08503 7.14485 1.39525 7.28612 1.73664C7.4274 2.07803 7.50005 2.4439 7.49992 2.81337Z" fill="#8332B1"/>
<path d="M24.3749 2.81337C24.3747 3.55954 24.078 4.27505 23.5502 4.80249C23.0224 5.32994 22.3067 5.62612 21.5605 5.62587C20.8144 5.62562 20.0989 5.32897 19.5714 4.80117C19.044 4.27337 18.7478 3.55766 18.748 2.81149C18.7483 2.06532 19.0449 1.34981 19.5727 0.822367C19.8341 0.561202 20.1443 0.354069 20.4857 0.212794C20.8271 0.0715198 21.193 -0.00113004 21.5624 -0.00100692C21.9319 -0.00088381 22.2977 0.0720098 22.639 0.213512C22.9803 0.355014 23.2904 0.562354 23.5515 0.823693C23.8127 1.08503 24.0198 1.39525 24.1611 1.73664C24.3024 2.07803 24.375 2.4439 24.3749 2.81337Z" fill="#8332B1"/>
<path d="M7.5 7.5H1.875C1.37772 7.5 0.900806 7.69754 0.549175 8.04918C0.197544 8.40081 0 8.87772 0 9.375L0 18.75H1.875V30H4.21875V18.75H5.15625V30H7.5V18.75H9.375V9.375C9.375 8.87772 9.17746 8.40081 8.82582 8.04918C8.47419 7.69754 7.99728 7.5 7.5 7.5Z" fill="#8332B1"/>
<path d="M28.5638 15L30 13.9594L26.0944 7.92C26.0091 7.79051 25.893 7.68419 25.7566 7.61056C25.6201 7.53694 25.4675 7.49831 25.3125 7.49813H17.8125C17.6574 7.4978 17.5046 7.53622 17.3681 7.60989C17.2315 7.68356 17.1155 7.79015 17.0306 7.92L13.125 13.9594L14.5612 15L17.8031 10.7925L18.93 13.4213L15.0019 20.6231H18.5963L19.2206 29.9981H21.0956V20.6231H22.0331V29.9981H23.9081L24.5325 20.6231H28.1269L24.1988 13.4194L25.3256 10.7906L28.5675 14.9981L28.5638 15Z" fill="#8332B1"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

</template>
