<template>
    <AppPublicLayout>
        <template v-slot:header>
            <AppHeader :countFavorites="favoritesAmount.count" />
        </template>
        <template v-slot:main>
            <div class="massagist-loading" v-if="massagist.loading">
                <div class="photo-loading"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                <div class="info-loading">
                    <div class="line-one"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                    <div class="line-two"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                    <div class="line-three"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                    <div class="line-four"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                </div>
                <div class="clients-info-loading">
                    <div class="line-one"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                    <div class="line-two"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                    <div class="line-three"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                    <div class="line-four"><div class="line" :style="`left: ${animation.left}%`"></div></div>
                </div>
            </div>
            <div class="main-content" v-if="!massagist.loading">
                <div class="app-title">Массаж в <span>твоем</span> городе</div>
                <div class="professional-information">
                    <div class="gallary">
                        <AppGallary :photos="massagist.massagist.photos" :id="massagist.massagist.id" />
                    </div>
                    <div class="information">
                        <div class="information-header">
                            <div class="info">
                                <span class="name">{{ massagist.massagist.username }}, </span>
                                <span class="age" v-if="massagist.massagist.age">{{ massagist.massagist.age }} лет</span>
                            </div>
                            <div class="icons">
                                <AppHeartIconAccent />
                            </div>
                        </div>
                        <div class="phone">
                            <AppPhoneIcon />
                            <span class="phone-number"><a :href="`tel:${massagist.massagist.phone}`">{{ 
                                massagist.massagist.phone.substring(0, 2) + ' (' + 
                                massagist.massagist.phone.substring(2, 5) + ') ' + 
                                massagist.massagist.phone.substring(5, 8) + '-' +
                                massagist.massagist.phone.substring(8, 10) + '-' +
                                massagist.massagist.phone.substring(10, 12)}}</a></span>
                        </div>
                        <div class="working-time">
                            <span class="working">Работаю: </span>
                                <span class="time">
                                <div class="day" v-for="day, index in workingDays" :key="index">
                                    <BasicCheckInput 
                                        class="checkbox-input"
                                        :options="day"
                                        disabled
                                        :checked="isInputChecked(day.id)"
                                         />
                                </div>
                            </span>
                            <span class="working-time" v-if="massagist.massagist.working_hours">
                                    {{ 'с ' + massagist.massagist.working_hours.substring(0, 5) +
                                        ' до ' +  massagist.massagist.working_hours.substring(6, 12) }}
                            </span>
                        </div>
                        <div class="working-place-and-prices">
                            <div class="my-place">
                                <span class="label">Принимаю у себя:</span>
                                <div class="one-hour"><span class="time">1 час</span><div class="price">{{ massagist.massagist.my_place_per_hour_price }} руб.</div></div>
                                <div class="two-hours"><span class="time">2 часа</span><div class="price">{{ massagist.massagist.my_place_per_two_hours_price }} руб.</div></div>
                            </div>
                            <div class="your-place">
                                <span class="label">Выезжаю к вам:</span>
                                <div class="one-hour"><span class="time">1 час</span><div class="price">{{ massagist.massagist.your_place_per_hour_price }} руб.</div></div>
                                <div class="two-hours"><span class="time">2 часа</span><div class="price">{{ massagist.massagist.your_place_per_two_hours_price }} руб.</div></div>
                            </div>
                            <div class="my-clients" v-if="massagist.massagist.massage_to">
                                <span class="label">Делаю массаж:</span>
                                <div class="option" v-if="massagist.massagist.massage_to.match('Men')"><span class="icon"><AppManIcon /></span>Мужчинам</div>
                                <div class="option" v-if="massagist.massagist.massage_to.match('Women')"><span class="icon"><AppWomanIcon /></span>Женщинам</div>
                                <div class="option" v-if="massagist.massagist.massage_to.match('Couples')"><span class="icon"><AppCoupleIcon/></span>Парам</div>
                            </div>
                            <div class="age-conditions" v-if="!massagist.massagist.client_age_from && !massagist.massagist.client_age_till">Ограничений по возрасту нет</div>
                            <div class="age-conditions" v-if="massagist.massagist.client_age_from">Возраст клиента от: {{ massagist.massagist.client_age_from }}</div>
                            <div class="age-conditions" v-if="massagist.massagist.client_age_till">Возраст клиента до: {{ massagist.massagist.client_age_till }}</div>
                            
                        </div>
                    </div>
                </div>
                <div class="about-me">
                    <h3>О себе:</h3>
                    <p v-if="massagist.massagist.about_me_approved">{{ massagist.massagist.about_me }}</p>
                    <p v-if="!massagist.massagist.about_me_approved">Описание находится на проверке модератора</p>
                </div>
                <div class="offers">
                    <h3>Виды услуг</h3>
                    <div class="offer-list">
                            Основные виды массажа:
                        <ul v-if="massagist.massagist.base_massage_types">
                            <span v-for="type, index in massagist.massagist.base_massage_types" :key="index">
                                <li><span class="icon"><AppLittleCheck /></span><span class="offer">{{ type.label }}</span></li>
                            </span>
                        </ul>
                            Дополнительные виды массажа:
                        <ul v-if="massagist.massagist.additional_massage_types">
                            <span v-for="type, index in massagist.massagist.additional_massage_types" :key="index">
                                <li><span class="icon"><AppLittleCheck /></span><span class="offer">{{ type.label }}</span></li>
                            </span>
                        </ul>
                    </div>
                </div>
                <!-- <div class="location-and-reviews">
                    <div class="location">
                        <h3 class="city">Москва</h3>
                        <div class="sub-station">
                            <div class="label">
                                <span class="icon"><AppSubwayIcon /></span>
                                <span class="station-name">м. Пражская, Южная</span>
                            </div>
                            <div class="map">
                                <img :src="require(`@/assets/images/map.png`)" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="reviews">
                        <h3>Отзывы</h3>
                        <ul>
                            <li>
                                <div class="photo">
                                    <img :src="require(`@/assets/images/client-photo.png`)" alt="">
                                </div>
                                <div class="content">
                                    <span class="name">Иван</span>
                                    <p class="review">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas odio enim, sed ornare est sed. Viverra nulla </p>
                                    <a href="" class="more">подробнее</a>                              
                                </div>                            
                            </li>
                            <li>
                                <div class="photo">
                                    <img :src="require(`@/assets/images/client-photo.png`)" alt="">
                                </div>
                                <div class="content">
                                    <span class="name">Иван</span>
                                    <p class="review">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas odio enim, sed ornare est sed. Viverra nulla </p>
                                    <a href="" class="more">подробнее</a>                              
                                </div>                            
                            </li>
                            <li>
                                <div class="photo">
                                    <img :src="require(`@/assets/images/client-photo.png`)" alt="">
                                </div>
                                <div class="content">
                                    <span class="name">Иван</span>
                                    <p class="review">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas odio enim, sed ornare est sed. Viverra nulla </p>
                                    <a href="" class="more">подробнее</a>                              
                                </div>                            
                            </li>
                        </ul>
                        <a href="" class="all-reviews">Все отзывы</a>
                    </div>
                </div>   -->
            </div>
        </template>
        <template v-slot:footer>
            <AppFooter />
            <AppNavigationMobile />
            <a :href="`tel:${massagist.massagist.phone}`" v-if="!massagist.loading" class="fast-call">
                <AppLargePhoneIcon />
            </a>
        </template>
    </AppPublicLayout>
</template>

<script>
import AppPublicLayout from "@/layouts/user/public/app-public-layout.vue";
import AppHeader from "@/sections/app-header.vue";
import AppFooter from "@/sections/app-footer.vue";
import AppNavigationMobile from "@/sections/app-navigation-mobile.vue";
import AppHeartIconAccent from "@/components/icons/app-little-heart-icon.vue";
import BasicCheckInput from "@/components/inputs/app-check-input.vue";
import AppPhoneIcon from "@/components/icons/app-phone-icon.vue";
import AppManIcon from "@/components/icons/app-man-icon.vue";
import AppWomanIcon from "@/components/icons/app-woman-icon.vue";
import AppCoupleIcon from "@/components/icons/app-couple-icon.vue";
import AppDocsIcon from "@/components/icons/app-docs-icon.vue";
import AppLittleCheck from "@/components/icons/app-little-check.vue";
import AppSubwayIcon from "@/components/icons/app-subway-icon.vue";
import AppGallary from "@/components/gallaries/app-gallary.vue";
import AppLargePhoneIcon from "@/components/icons/app-large-phone-icon.vue";
import massageTypes from "@/database/massageInputTypes.js";
import additionalMassageTypes from "@/database/massageInputTypesAdditional.js";
import massagistsAPI from "@/api/massagist.js";
import workingDaysArray from "@/database/workingDays.js";

import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
// import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
    export default {
        name: "Massagist",
        components: {
            AppPublicLayout,
            AppHeader,
            AppFooter,
            AppNavigationMobile,
            AppHeartIconAccent,
            AppPhoneIcon,
            AppManIcon,
            AppWomanIcon,
            AppCoupleIcon,
            AppDocsIcon,
            AppLittleCheck,
            AppSubwayIcon,
            AppGallary,
            AppLargePhoneIcon,
            BasicCheckInput
            // AppRegularBtn
        },
        setup(){
            const route = useRoute();
            const store = useStore();

            const massageTypesList = reactive(massageTypes);
            const additionalMassageTypesList = reactive(additionalMassageTypes)
            const gallaryOptions = reactive({
                photos: [
                    {
                    id: 1,
                    url: '1.png'
                },
                {
                    id: 2,
                    url: '2.png'
                },
                {
                    id: 3,
                    url: '3.png'
                },
                {
                    id: 4,
                    url: '4.png'
                },
                {
                    id: 5,
                    url: '5.png'
                },
                {
                    id: 6,
                    url: '6.png'
                },
                {
                    id: 7,
                    url: '7.png'
                }
                ],
                isChecked: true,
                isVip: false,
                rating: {
                    isRating: false
                }
                
            })

            const animation = reactive({
                left: 110,
                moveLine: () => {
                    if(massagist.loading){
                        setInterval(() => {
        
                            animation.left++;
                            if(animation.left > 110){
                            animation.left = -40;
                            }
                        }, 5)
                    } else {
                        return
                    }
                }
            })

            const workingDays = workingDaysArray;

            const isInputChecked = (input) => {
                if(massagist.massagist.working_days){
                    if(massagist.massagist.working_days.match(input)){
                        return true
                    } else return false
                } else {
                    return false
                }
            }

            const massagist = reactive({
                loading: true,
                massagist: null,
                getMassagist: async (id) => {
                    let mass = store.state.massagists.filter(m => +m.id === +id);
                    if(mass[0]){
                        massagist.massagist = mass[0];
                        if(massagist.massagist.base_massage_types){
                            massagist.massagist.base_massage_types = massagist.massagist.base_massage_types.split(',');
                            massagist.massagist.base_massage_types = massagist.massagist.base_massage_types.map(type => type = type.trim());
                            let baseMassageTypes = []
                            massageTypesList.forEach(item => {
                                massagist.massagist.base_massage_types.forEach(type => {
                                    if(type === item.name){
                                        baseMassageTypes.push(item)
                                    }
                                })
                            })
                            massagist.massagist.base_massage_types = baseMassageTypes;
                        }
    
                        if(massagist.massagist.additional_massage_types){
                            massagist.massagist.additional_massage_types = massagist.massagist.additional_massage_types.split(',');
                            massagist.massagist.additional_massage_types = massagist.massagist.additional_massage_types.map(type => type = type.trim());
                            let additionalMassageTypesArr = []
                            additionalMassageTypesList.forEach(item => {
                                massagist.massagist.additional_massage_types.forEach(type => {
                                    if(type === item.name){
                                        additionalMassageTypesArr.push(item)
                                    }
                                })
                            })
                            massagist.massagist.additional_massage_types = additionalMassageTypesArr;
                        }
                        massagist.loading = false;
                    } else {
                        await massagistsAPI.getMassagistInfo({ id: id })
                            .then(response => massagist.massagist = response.data)
                            .catch(err => console.log(err))
                        await massagistsAPI.getAllMassagistPhotos({ massagist_id: id })
                            .then(response => massagist.massagist.photos = response.data)
                            .catch(err => console.log(err))
    
                        
                        if(massagist.massagist.base_massage_types){
                            massagist.massagist.base_massage_types = massagist.massagist.base_massage_types.split(',');
                            massagist.massagist.base_massage_types = massagist.massagist.base_massage_types.map(type => type = type.trim());
                            let baseMassageTypes = []
                            massageTypesList.forEach(item => {
                                massagist.massagist.base_massage_types.forEach(type => {
                                    if(type === item.name){
                                        baseMassageTypes.push(item)
                                    }
                                })
                            })
                            massagist.massagist.base_massage_types = baseMassageTypes;
                        }
    
                        if(massagist.massagist.additional_massage_types){
                            massagist.massagist.additional_massage_types = massagist.massagist.additional_massage_types.split(',');
                            massagist.massagist.additional_massage_types = massagist.massagist.additional_massage_types.map(type => type = type.trim());
                            let additionalMassageTypesArr = []
                            additionalMassageTypesList.forEach(item => {
                                massagist.massagist.additional_massage_types.forEach(type => {
                                    if(type === item.name){
                                        additionalMassageTypesArr.push(item)
                                    }
                                })
                            })
                            massagist.massagist.additional_massage_types = additionalMassageTypesArr;
                        }

                    massagist.loading = false;
                    }
                    

                }

            })

            const favoritesAmount = reactive({
                count: 0
            });

            const countFavorites = () => {
                let count;
                const favorites = JSON.parse(localStorage.getItem('favorites'));
                favorites ? count = favorites.length : count = 0;

                favoritesAmount.count = count;
            }

            onMounted(countFavorites, massagist.getMassagist(route.params.id), animation.moveLine())
        
                return {
                    massageTypesList,
                    gallaryOptions,
                    favoritesAmount,
                    massagist,
                    workingDays,
                    isInputChecked,
                    animation
                }
            }
    }
</script>

<style lang="scss" scoped>
    .fast-call{
        position: fixed;
        right: 5%;
        bottom: 15%;
    }

    .massagist-loading{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .photo-loading{
            width: 45%;
            height: 500px;
            background-color: $accent;
            border: transparent;
            border-radius: 0.5em;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 2em 1em;
            overflow: hidden;
            .line{
                width: 1px;
                height: 600px;
                background-color: rgba(225, 231, 236, 0.2);
                position: absolute;
                top: -20%;
                left: 20%;
                box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                transform: rotate(25deg);
            }
        }
        .info-loading{
            width: 45%;
            height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 3em;
            div{
                border: transparent;
                border-radius: 5px;
            }
            .line-one{
                width: 100%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
            .line-two{
                width: 80%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
            .line-three{
                width: 70%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
            .line-four{
                width: 60%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
        }
        .clients-info-loading{
            width: 95%;
            height: 500px;
            padding: 5% 0 0 6%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            div{
                border: transparent;
                border-radius: 5px;
            }
            .line-one{
                width: 90%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
            .line-two{
                width: 80%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
            .line-three{
                width: 70%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
            .line-four{
                width: 60%;
                height: 50px;
                background-color: $accent;
                overflow: hidden;
                position: relative;
                .line{
                    width: 1px;
                    height: 200px;
                    background-color: rgba(225, 231, 236, 0.2);
                    position: absolute;
                    top: -20%;
                    left: 20%;
                    box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
                    transform: rotate(25deg);
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
    .massagist-loading{
        .photo-loading{
            width: 90%;
        }
        .info-loading{
            width: 90%;

        }
    }
}

    .main-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .app-title{
            font-size: 1.5em;
            font-weight: 700;
            color: $black;
            span{
                color: $accent-dark;
            }
        }
        .professional-information{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            .gallary{
                width: 100%;
            }
            .information{
                width: 100%;
                padding: 2em 0;
                .information-header{
                    width: 90%;
                    justify-content: space-around;
                    display: flex;
                    align-items: center;
                    // flex-direction: column;
                    .info{
                        margin: 0.3em 0;
                        .name{
                            font-size: 2em;
                            font-weight: 700;
                        }
                        .age{
                            font-size: 2em;
                            font-weight: 700;
                        }
                    }
                    .icons{
                        margin: 0.3em 0;
                        display: flex;
                        justify-content: space-around;
                    }

                }
                .phone{
                    margin: 0.3em 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 1em;
                    .phone-number{
                        margin-left: 1em;
                        a{
                            text-decoration: none;
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }
                .working-time{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0.5em;
                    .working{
                        font-weight: 700;
                        margin-right: 10px;
                        margin-bottom: 1em;
                    }
                    .time{
                        font-weight: 700;
                        display: flex;
                        flex-wrap: wrap;
                        color: $accent-dark;
                        .day{
                            .checkbox-input{
                                margin-right: 15px;
                            }
                        }
                    }
                    .working-time{
                        font-weight: bold;
                        color: $accent-dark;
                    }
                }
                .working-place-and-prices{
                    padding-top: 1em;
                    .my-place{
                        display: flex;
                        flex-direction: column;
                        margin: 0.5em;
                        .label{
                            font-weight: 700;
                            margin-bottom: 0.5em;
                            font-size: 1.3em;                           
                        }
                        .one-hour{
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            margin-top: 0.5em;
                            .time{
                                font-weight: 700;
                            }
                            .price{
                                padding: 0.5em 1em;
                                background-color: $white;
                                border: 1px solid $accent-dark;
                            }
                        }
                        .two-hours{
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            margin-top: 0.5em;
                            .time{
                                font-weight: 700;
                            }
                            .price{
                                padding: 0.5em 1em;
                                background-color: $white;
                                border: 1px solid $accent-dark;
                            }
                        }
                    }
                    .your-place{
                        display: flex;
                        flex-direction: column;
                        margin: 0.5em;
                        .label{
                            font-weight: 700;
                            margin-bottom: 0.5em;
                            font-size: 1.3em;                          
                        }
                        .one-hour{
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            margin-top: 0.5em;
                            .time{
                                font-weight: 700;
                            }
                            .price{
                                padding: 0.5em 1em;
                                background-color: $white;
                                border: 1px solid $accent-dark;
                            }
                        }
                           .two-hours{
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            margin-top: 0.5em;
                            .time{
                                font-weight: 700;
                            }
                            .price{
                                padding: 0.5em 1em;
                                background-color: $white;
                                border: 1px solid $accent-dark;
                            }
                        }
                    }
                    .my-clients{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .label{
                            font-weight: 700;
                            font-size: 1.3em;
                            margin: 0.5em;
                        }
                        .option{
                            display: flex;
                            font-weight: 700;
                            align-items: center;
                            margin: 0.3em 0;
                            span{
                                margin-right: 0.5em;
                            }
                        }
                    }
                    .age-conditions{
                        font-weight: 700;
                        margin: 0.5em;
                        text-align: center;
                        color: $accent-dark;
                    }
                    .documents{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .label{
                            display: flex;
                            font-weight: 700;
                            align-items: center;
                            font-size: 1.3em;
                            .icon{
                                margin-right: 0.5em;
                            }
                        }
                        .docs{
                            display: flex;
                            img{
                                margin: 0.5em;
                            }
                        }
                    }
                }
            }
        }
        .about-me{
            padding: 1em;
            h3{
                margin-bottom: 1em;
            }
        }
        .offers{
            .offer-list{
                ul{
                    list-style: none;
                    li{
                        display: flex;
                        align-items: center;
                        margin: 0.3em 0;
                        .icon{
                            margin-right: 0.5em;
                        }
                        .offer{
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .location-and-reviews{
            padding: 0.3em;
            .location{
                h3{
                    margin: 0.5em 0;

                }
                .sub-station{
                    .label{
                        display: flex;
                        align-items: center;
                        .icon{
                            margin-right: 0.5em;                            
                        }
                        .station-name{
                            font-weight: 700;
                        }
                    }
                    .map{
                        max-width: 100%;
                        img{
                            max-width: 100%;
                        }
                    }
                }
            }
            .reviews{
                padding: 0.5em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h3{
                    margin-bottom: 1em;
                    align-self: flex-start;
                    font-size: 1.3em;
                }
                ul{
                    list-style: none;
                    li{
                        display: flex;
                        align-items: center;
                        .photo{
                            width: 100px;
                            height: 100px;
                            border-radius: 100%;
                            border: 1px solid $accent-dark;
                            margin-right: 1em;
                        }
                        .content{
                            display: flex;
                            flex-direction: column;
                            .name{
                                font-size: 1.4em;
                                font-weight: 700;
                            }
                            .review{
                                font-weight: 700;
                            }
                            .more{
                                color: $accent-dark;
                                align-self: flex-end;
                                text-decoration: none;
                                font-weight: 700;
                            }
                        }
                    }
                }
                .all-reviews{
                    text-transform: uppercase;
                    text-decoration: none;
                    color: $accent-dark;
                    font-weight: 700;
                }
            }
        }
    }

    @media screen and(min-width: 768px){
     .fast-call{
        display: none;
    }
     .main-content{
        .professional-information{
            .information{
                width: 100%;
                padding: 2em 0;
                .information-header{
                    justify-content: center;
                    align-items: center;
                    .info{
                        margin: 0.3em 1em 0 0;
                    }
                }
                .phone{
                    .phone-number{
                        a{
                            font-size: 1.2em;
                        }
                    }
                }
                .working-place-and-prices{
                    width: 70%;
                    margin: 0 auto;
                    .my-clients{
                        flex-direction: row;
                        align-items: center;
                        .option{
                            display: flex;
                            font-weight: 700;
                            align-items: center;
                            margin: 0.3em 0.5em;
                            span{
                                margin-right: 0.5em;
                            }
                        }
                    }
                }
            }
        }
        .about-me{
            width: 85%;
        }
        .offers{
            width: 85%;
            .offer-list{
                h3{
                    margin-bottom: 1em;
                }
                ul{
                    list-style: none;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 1em;
                    li{
                        margin: 0.3em 1em;
                        min-width: 200px;
                    }
                }
            }
        }
        .location-and-reviews{
            padding: 0 2em;
            .location{ 
                .sub-station{
                    .map{
                        margin: 1em 0;
                    }
                }
            }
            .reviews{
                padding-bottom: 1em;             
                ul{                    
                    li{                        
                        padding: 1em 0;
                    }
                }
            }
        }
    }
}
@media screen and(min-width: 1280px){
    .main-content{
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .professional-information{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            .gallary{
                width: 60%;
            }
            .information{
                width: 40%;
                .information-header{
                    width: 100%;
                    justify-content: flex-start;
                    align-items: center;
                    .info{
                        margin: 0.3em 1em 0 0;
                    }
                }
                .phone{                    
                    justify-content: flex-start;
                }
                .working-time{
                    justify-content: flex-start;
                }
                .working-place-and-prices{
                    padding-top: 1em;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    .my-place{
                       width: 50%;      
                    }
                    .your-place{
                        width: 50%;
                    }
                    .my-clients{
                        flex-direction: row;
                        flex-wrap: wrap;
                        .label{
                            width: 100%;
                        }
                        .option{
                            margin: 0.3em 1em 0 0;
                        }
                    }
                    .age-conditions{
                        margin: 0.5em 0 2em;
                    }
                }
            }
        }
        .location-and-reviews{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .location{
                width: 45%;
                h3{
                    margin: 0.5em 0;

                }
                .sub-station{
                    .label{
                        display: flex;
                        align-items: center;
                        .icon{
                            margin-right: 0.5em;                            
                        }
                        .station-name{
                            font-weight: 700;
                        }
                    }
                    .map{
                        max-width: 100%;
                        img{
                            max-width: 100%;
                        }
                    }
                }
            }
            .reviews{
                width: 45%;
                padding: 0.5em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h3{
                    margin-bottom: 1em;
                    align-self: flex-start;
                    font-size: 1.3em;
                }
                ul{
                    list-style: none;
                    li{
                        display: flex;
                        align-items: center;
                        .photo{
                            width: 100px;
                            height: 100px;
                            border-radius: 100%;
                            border: 1px solid $accent-dark;
                            margin-right: 1em;
                        }
                        .content{
                            display: flex;
                            flex-direction: column;
                            .name{
                                font-size: 1.4em;
                                font-weight: 700;
                            }
                            .review{
                                font-weight: 700;
                            }
                            .more{
                                color: $accent-dark;
                                align-self: flex-end;
                                text-decoration: none;
                                font-weight: 700;
                            }
                        }
                    }
                }
                .all-reviews{
                    text-transform: uppercase;
                    text-decoration: none;
                    color: $accent-dark;
                    font-weight: 700;
                }
            }
        }
    }
    }
</style>