<template>
    <div class="app-gallary">
        <div class="gallary-main-photo" id="gallary-main-photo">
            <div class="options">
                <!-- <AppCheckIcon class="icon" v-if="isChecked" /> -->
                <!-- <AppVipIcon class="icon" v-if="isVip" /> -->
                <!-- <AppRatingIcon class="icon" :rating="rating.number" v-if="rating.isRating" /> -->
            </div>         
            <img :src="massagistPhotosPath + id + '/' + photos[mainPhoto].link" alt="" v-if="photos.length > 0 && photos[mainPhoto].approved">
            <span v-if="photos.length < 1">Фото не добавлены или находятся на проверке модератора</span>
        </div>
    
        <div class="sub-gallary" v-if="photos.length > 0">
            <div class="sub-gallary-photo" v-for="(photo, index) in photos" :key="index" @click="changeMainPhoto(index)">
                <a href="#gallary-main-photo"><img :src="massagistPhotosPath + id + '/' + photo.link" alt="" v-if="photo.approved"></a>
            </div>
        </div>
    </div>
</template>

<script>
import AppCheckIcon from "@/components/icons/app-check-icon.vue";
import AppVipIcon from "@/components/icons/app-vip-icon.vue";
import AppRatingIcon from "@/components/icons/app-rating-icon.vue";
import domain from "@/api/domain.js";

    export default {
        components: {
            AppCheckIcon,
            AppVipIcon,
            AppRatingIcon
        },
        props: {
            photos: {
                    type: Object,
                    default: []
                },
                id: {
                    type: Number,
                },
                isChecked: {
                    type: Boolean,
                    default: false
                },
                isVip: {
                    type: Boolean,
                    default: false
                },
                rating: {
                    isRating: {
                        type: Boolean,
                        default: false
                    },
                    number: {
                        type: Number,
                        default: null
                    }
            }            
        },
        data(){
            return{
                mainPhoto: 0,
                massagistPhotosPath: domain.massagistPhotosPath
            }
        },
        methods: {
            changeMainPhoto(index){
                this.mainPhoto = index;
            },
            getMainPhoto(){
                this.photos.forEach((photo, index) => {
                    if(photo.main_photo === 1){
                        this.mainPhoto = index;
                    }
                })
            },
            
        },
        mounted(){
            this.getMainPhoto();
        }
    }
</script>

<style lang="scss" scoped>
    .app-gallary{
        width: 100%;
        overflow: hidden;
        position: relative;
        .gallary-main-photo{
            width: 85%;
            height: 500px;
            overflow: hidden;
            margin: 1em auto;
            border-radius: 0.5em;
            border: 2px solid $accent-dark;
            position: relative;
            .options{
                position: absolute;
                top: 0;
                left: 0;
                height: 300px;
                width: 100px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 2em 0;
                align-items: center;
                z-index: 1000;
            }
            img{
                max-width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .sub-gallary{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .sub-gallary-photo{
                width: 45%;
                height: 220px;
                overflow: hidden;
                margin: 0.5em;
                border-radius: 0.5em;
                border: 2px solid $accent-dark;
                position: relative;
                img{
                    max-width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

@media screen and(min-width: 768px){
    .app-gallary{
        width: 100%;
        overflow: hidden;
        position: relative;
        .gallary-main-photo{
            width: 85%;
            height: 700px;
            overflow: hidden;
            margin: 1em auto;
            border-radius: 0.5em;
            border: 2px solid $accent-dark;
            position: relative;
            .options{
                position: absolute;
                top: 0;
                left: 0;
                height: 300px;
                width: 100px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 2em 0;
                align-items: center;
                z-index: 1000;
            }
            img{
                max-width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .sub-gallary{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .sub-gallary-photo{
                width: 45%;
                height: 220px;
                overflow: hidden;
                margin: 0.5em;
                border-radius: 0.5em;
                border: 2px solid $accent-dark;
                position: relative;
                img{
                    max-width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

@media screen and(min-width: 1024px) {
    .app-gallary{
        width: 90%;
        margin: 0 auto;
        .sub-gallary{
            .sub-gallary-photo{
                width: 25%;
                height: 220px;
                overflow: hidden;
                margin: 0.5em;
                border-radius: 0.5em;
                border: 2px solid $accent-dark;
                position: relative;
                img{
                    max-width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
// @media screen and(min-width: 1280px) {
//     .app-gallary{
//         width: 45%;
//         overflow: hidden;
//         position: relative;
//         .gallary-main-photo{
//             width: 95%;
//             height: 500px;
//             overflow: hidden;
//             margin: 1em auto;
//             border-radius: 0.5em;
//             border: 2px solid $accent-dark;
//             position: relative;
//             .options{
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 height: 300px;
//                 width: 100px;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: flex-start;
//                 padding: 2em 0;
//                 align-items: center;
//                 z-index: 1000;
//             }
//             img{
//                 max-width: 100%;
//                 position: absolute;
//                 top: 50%;
//                 left: 50%;
//                 transform: translate(-50%, -50%);
//             }
//         }
//         .sub-gallary{
//             display: flex;
//             justify-content: space-around;
//             flex-wrap: wrap;
//             .sub-gallary-photo{
//                 width: 45%;
//                 height: 220px;
//                 overflow: hidden;
//                 margin: 0.5em;
//                 border-radius: 0.5em;
//                 border: 2px solid $accent-dark;
//                 position: relative;
//                 img{
//                     max-width: 100%;
//                     position: absolute;
//                     top: 50%;
//                     left: 50%;
//                     transform: translate(-50%, -50%);
//                 }
//             }
//         }
//     }
// }
</style>