export default [
    
        {
            id: 'monday',
            label: 'Пн',
            name: "monday"
        },
        {
            id: 'tuesday',
            label: 'Вт',
            name: "tuesday"
        },
        {
            id: 'wednesday',
            label: 'Ср',
            name: "wednesday"
        },
        {
            id: 'thursday',
            label: 'Чт',
            name: "thursday"
        },
        {
            id: 'friday',
            label: 'Пт',
            name: "friday"
        },
        {
            id: 'saturday',
            label: 'Сб',
            name: "saturday"
        },
        {
            id: 'sunday',
            label: 'Вс',
            name: "sunday"
        },
    
]